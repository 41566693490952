
import { defineComponent,inject, PropType } from "vue";

import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import $ from "jquery";
import Modal from "@/components/UI/Modal";
import router from "@/router";
import qs from "qs";
  export default defineComponent({
    strict: false,
    name: "slotFooter",
    props: {
      tid: {
        type: String,
        default: "",
      },
      nclassId: {
        type: String,
        default: "0",
      },
      pid: {
        type: String,
        default: "",
      },
      checkvalue:{
        type:Number,
        default:null
      },
      type: {
        type: String,
        default: "1",
      },
      isFav: {
        type: Boolean,
        default: false,
      },
      viewCartText: {
        type: String,
        default: "查看购物车",
      },
      lotid: {
        type: String,
        default: "0"
      },
      tcid: {
        type: String,
        default: "0"
      },
      stock: {
        type: Number,
        default: null
      },
      buttonType:{
        type:Number,
        default:1
      },
        special:{
            type:Number,
            default:null
        }
    },
    setup(props) {
      //  add shop car
      const { addShopCar,storgeBackstage } = useShopCar();
      const userInfo = inject("userInfo") as any;
        console.log(userInfo.id,"6666");
        console.log(userInfo.isReview,"888888");
        // console.log(special,"9999999999999");
      //查看购物车
      function toViewCart() {

          if(!userInfo || !userInfo.id){
              Modal({
                  title: "温馨提示",
                  content: '<div>登录后才可查看购物车</div>',
                  dangerouslyUseHTMLString: true,
                  confirmText:"前往登录",
                  onConfirm: () => {
                      router.push({
                          name: "Login",
                          query: {
                              redirect: router.currentRoute.value.fullPath,
                          },
                      });
                  },
                  onCancel: () => {
                      console.log('取消')
                  },
              });
          }else{
              router.push({name: "ShopCar"});
          }

      }
      //去申请试用
      function detailToApplyOrder(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, category: 1,cartType: 1,checkValue: number) {

          storgeBackstage(id,tid, 0, 1,tcid,1,1,checkValue);
          //子组件中修改父组件传递过来的值
          this.$emit('update:buttonType', 1)

      }

      function collectAction() {
        const formData = new FormData();
        formData.append("id", props.pid);
        formData.append("type", props.type);
        api
                .post("/M/Server/Collection", formData, {
                  params: {
                    validate: true,
                  },
                })
                .then((res) => {
                  if (res) {
                    Toast({
                      title: res.data.msg,
                      type: "success",
                    });
                    $("#collect img").prop(
                            "src",
                            'https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon53-120210417.png'
                    );
                  }
                })
                .catch((err) => {
                  Toast({
                    title: err.message || "收藏失败，请联系管理员",
                    type: "error",
                  });
                });
      }

      function standarddownloadcheck() {
          if(!userInfo || userInfo.isReview == 0) {
              Modal({
                  title: "温馨提示",
                  content: '<div>您的资质信息不完整，请移步至电脑端：https://www.bncc.com<br>会员中心进行上传</div>',
                  dangerouslyUseHTMLString: true,
                  // confirmText: "上传资质",
                  // onConfirm: () => {
                  //     router.push({
                  //         name: "UserInfo",
                  //         query: {
                  //             redirect: router.currentRoute.value.fullPath,
                  //         },
                  //     });
                  // },
                  onCancel: () => {
                      console.log('取消')
                  },
              });
          }
        // api
        // .post("/M/Server/DownloadCheck", formData)
        // .then((res): void => {
        //   if(!res.data.success){
        //     if(res.data.msg == "请先登录！"){
        //         Toast({
        //             title: res.data.msg,
        //             type: "error"
        //         });
        //     }
        //     if(res.data.msg == "账户积分不足,请充值!"){
        //         Toast({
        //             title: res.data.msg,
        //             type: "error"
        //         });
        //     }
        //     if (res.data.msg == "文件不存在,请联系客服!"){
        //          Toast({
        //             title: res.data.msg,
        //             type: "error"
        //         });
        //     }
        //     if(res.data.msg == "您已购买过此标准，再次下载不需要扣除积分。"){
        //         window.location.href = "/M/Server/Download?id=" + props.pid;
        //     }
        //   }else{
        //        const fData = new FormData();
        //         fData.append("id", props.pid);
        //       api.post("/M/Server/StandardPurchase", fData).then(data => {
        //           if(data.data.success){
        //             if(data.data.msg == "请先登录再执行此操作!"){
        //                 Toast({
        //                     title: data.data.msg,
        //                     type: "error"
        //                 });
        //             }
        //             if(data.data.msg == "您已购买过此标准!"){
        //                 window.location.href = "/M/Server/Download?id=" + props.pid;
        //             }
        //           }else{
        //               window.location.href = "/M/Server/Download?id=" + props.pid;
        //           }
        //       })
        //   }

      // })
      // .catch(err => {
      //   Toast({
      //     title: err.msg || "收藏失败，请联系管理员",
      //     type: "error"
      //   });
      // });
    }

      return {
        // handleAddCart,
        collectAction,
        standarddownloadcheck,
        toViewCart,
        detailToApplyOrder,
        addShopCar,
        storgeBackstage,
        userInfo
      };
    },
  });
